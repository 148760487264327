export function isPlayedWindup(windup) {
    return windup.length === 2;
}
export function memberIsWindup(member) {
    // If it's not an array it can't be a windup
    if (!Array.isArray(member)) {
        return false;
    }
    // If it has less or more than three members it's not a windup
    if (member.length !== 3) {
        return false;
    }
    // If its first or second members are not arrays it's not a windup
    if (!Array.isArray(member[0]) || !Array.isArray(member[1])) {
        return false;
    }
    // Past here we just have to hope ElementType isn't a windup.
    return true;
}
export function windupFromString(str, metadata) {
    return [[], str.split(""), metadata];
}
export function newWindup(arg, metadata) {
    return [[], arg, metadata];
}
export function isUnplayed(windup) {
    const [played, remaining] = windup;
    if (played.length > 0) {
        return false;
    }
    return remaining.reduce((unplayed, member) => {
        if (memberIsWindup(member))
            if (memberIsWindup(windup) && unplayed) {
                return isUnplayed(member);
            }
        return unplayed;
    }, true);
}
export function isFinished([_played, remaining,]) {
    return remaining.length === 0;
}
export function fastForward(windup) {
    const forwardedWindup = next(windup);
    if (isFinished(forwardedWindup)) {
        return forwardedWindup;
    }
    return fastForward(forwardedWindup);
}
export function rewind(windup) {
    if (isUnplayed(windup)) {
        return windup;
    }
    const [played, remaining, metadata] = windup;
    const mapRewind = (member) => {
        if (memberIsWindup(member)) {
            return rewind(member);
        }
        return member;
    };
    return [
        [],
        [...played.map(mapRewind), ...remaining.map(mapRewind)],
        metadata,
    ];
}
export function windupAsString(windup) {
    const [played, remaining] = windup;
    const stringify = (member) => {
        if (memberIsWindup(member)) {
            return windupAsString(member);
        }
        return member;
    };
    return [
        played.map(stringify).join(""),
        remaining.map(stringify).join(""),
    ].join("");
}
export function lastPlayedMember([played, remaining,]) {
    const playedFromRemaining = remaining.reduce((playedEl, member) => {
        if (memberIsWindup(member)) {
            if (!isUnplayed(member)) {
                return lastPlayedElement(member);
            }
        }
        return playedEl;
    }, undefined);
    if (playedFromRemaining) {
        return playedFromRemaining;
    }
    const last = played[played.length - 1];
    return last;
}
export function lastPlayedElement(windup) {
    const lastPlayed = lastPlayedMember(windup);
    if (memberIsWindup(lastPlayed)) {
        return lastPlayedElement(lastPlayed);
    }
    return lastPlayed;
}
export function playedElements([played, remaining,]) {
    const playedTransformed = played.map((member) => {
        if (memberIsWindup(member)) {
            const [_played, _remaining, metadata] = member;
            return [playedElements(member), metadata];
        }
        return member;
    });
    const [firstRemaning] = remaining;
    if (memberIsWindup(firstRemaning) && !isUnplayed(firstRemaning)) {
        const [_playedRemaining, _remaining, metadata] = firstRemaning;
        return [
            ...playedTransformed,
            [playedElements(firstRemaning), metadata],
        ];
    }
    return playedTransformed;
}
export function nextElement([_played, remaining,]) {
    const [nextVal] = remaining;
    if (memberIsWindup(nextVal)) {
        return nextElement(nextVal);
    }
    return nextVal;
}
export function next(windup) {
    // start
    // [[], [[[], ["h", "i"]], [[], ["n", "o"]]]]
    // next
    // [[], [[["h"], ["i"]], ["", ["n", "o"]]]]
    // next
    // [["h", "i"], [["n", "o"]]]
    // next
    // [["h", "i"] ["n", "o"]], []]
    if (isFinished(windup)) {
        return windup;
    }
    const [played, remaining, metadata] = windup;
    const [firstRemaining, ...restRemaining] = remaining;
    if (memberIsWindup(firstRemaining)) {
        const nextFirstRemaining = next(firstRemaining);
        if (isFinished(nextFirstRemaining)) {
            return [[...played, nextFirstRemaining], restRemaining, metadata];
        }
        return [played, [nextFirstRemaining, ...restRemaining], metadata];
    }
    return [[...played, firstRemaining], restRemaining, metadata];
}
