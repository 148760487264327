import React from "react";
import { playedElements, isPlayedWindup } from "../Windup";
export function renderStringWindup(windup) {
    const played = isPlayedWindup(windup) ? windup[0] : playedElements(windup);
    const inner = played.reduce((acc, playedEl) => {
        if (typeof playedEl === "string") {
            return acc + playedEl;
        }
        return acc + renderStringWindup(playedEl);
    }, "");
    return inner;
}
const VOID_TAGS = [
    "area",
    "base",
    "br",
    "col",
    "command",
    "embed",
    "hr",
    "img",
    "input",
    "keygen",
    "link",
    "meta",
    "param",
    "source",
    "track",
    "wbr"
];
export function renderChildrenWindup(windup) {
    const metadata = isPlayedWindup(windup) ? windup[1] : windup[2];
    const played = isPlayedWindup(windup) ? windup[0] : playedElements(windup);
    const Outer = metadata.element || React.Fragment;
    if (metadata.props && Object.keys(metadata.props).includes("children")) {
        return React.createElement(Outer, Object.assign({}, metadata.props));
    }
    const inner = played.reduce((acc, playedEl) => {
        if (typeof playedEl === "string") {
            const accButLast = acc.slice(0, acc.length - 1);
            const last = acc[acc.length - 1];
            return last && typeof last === "string"
                ? [...accButLast, last + playedEl]
                : [...acc, playedEl];
        }
        return [...acc, renderChildrenWindup(playedEl)];
    }, []);
    if (typeof metadata.element === "string" &&
        VOID_TAGS.includes(metadata.element)) {
        return React.createElement(Outer, Object.assign({ key: metadata.key }, metadata.props));
    }
    return (React.createElement(Outer, Object.assign({ key: metadata.key }, metadata.props), inner));
}
