import React from "react";
import { isFinished, memberIsWindup } from "../Windup";
export function defaultGetPace(lastChar, nextChar) {
    switch (lastChar) {
        case "—":
        case "…":
            return 200;
        case ".":
        case ",":
            return 150;
        case "?":
        case "!":
            if (nextChar !== "!" && nextChar !== "?") {
                return 150;
            }
        case "-":
        case " ":
        case "\n":
            return 0;
        default:
            return 20;
    }
}
const Pace = ({ children }) => {
    return React.createElement(React.Fragment, null, children);
};
export function isPaceElement(element) {
    return element.type === Pace;
}
export function isMsProp(props) {
    if ("ms" in props) {
        return true;
    }
    return false;
}
export function paceFromWindup(windup) {
    if (isFinished(windup)) {
        return undefined;
    }
    const [_played, remaining, metadata] = windup;
    const [firstRemaining] = remaining;
    if (firstRemaining && memberIsWindup(firstRemaining)) {
        return paceFromWindup(firstRemaining);
    }
    return metadata.pace;
}
export default Pace;
