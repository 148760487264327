import React from "react";
import { memberIsWindup, lastPlayedMember, isUnplayed } from "../Windup";
const OnChar = ({ children }) => {
    return React.createElement(React.Fragment, null, children);
};
export function isOnCharElement(element) {
    return element.type === OnChar;
}
// Should return onChars from
// every just played member that is a windup
// any first remaining that is played
export function onCharsFromWindup(windup) {
    const [_played, remaining, metadata] = windup;
    const lastPlayed = lastPlayedMember(windup);
    const [firstRemaining] = remaining;
    let onChars = [];
    if (lastPlayed && memberIsWindup(lastPlayed)) {
        onChars.push(...onCharsFromWindup(lastPlayed));
    }
    if (firstRemaining &&
        memberIsWindup(firstRemaining) &&
        !isUnplayed(firstRemaining)) {
        onChars.push(...onCharsFromWindup(firstRemaining));
    }
    if (metadata.onChar) {
        onChars.push(metadata.onChar);
    }
    return onChars;
}
export default OnChar;
