import React, { useContext, useMemo } from "react";
import { newWindup, windupFromString, memberIsWindup } from "../Windup";
import { isPaceElement, isMsProp } from "./Pace";
import { isOnCharElement } from "./OnChar";
import { isPauseElement } from "./Pause";
import useWindup from "./useWindup";
import { renderChildrenWindup } from "./renderWindup";
const WindupContext = React.createContext({
    skip: () => {
        console.warn("Tried to use the useSkip hook outside of a WindupChildren component!!");
    },
    rewind: () => {
        console.warn("Tried to use the useRewind hook outside of a WindupChildren component!");
    },
    isFinished: false
});
export function useSkip() {
    const { skip } = useContext(WindupContext);
    return skip;
}
export function useRewind() {
    const { rewind } = useContext(WindupContext);
    return rewind;
}
export function useIsFinished() {
    const { isFinished } = useContext(WindupContext);
    return isFinished;
}
function reduceWindupArgs(prevArgs, children) {
    if (typeof children === "string") {
        return [...prevArgs, ...children.split("")];
    }
    if (typeof children === "number") {
        return [...prevArgs, ...children.toString().split("")];
    }
    if (!React.isValidElement(children)) {
        return prevArgs;
    }
    const { children: childrenChildren, ...restProps } = children.props;
    const paceMetaData = isPaceElement(children)
        ? {
            pace: (char) => {
                if (isMsProp(children.props)) {
                    return children.props.ms;
                }
                return children.props.getPace(char);
            }
        }
        : {};
    const onCharMetaData = isOnCharElement(children)
        ? {
            onChar: children.props.fn
        }
        : {};
    const keyProp = children.key ? { key: children.key } : {};
    if (isPauseElement(children)) {
        return [
            ...prevArgs,
            windupFromString(" ", {
                element: React.Fragment,
                ...keyProp,
                props: {},
                pace: () => children.props.ms
            })
        ];
    }
    if (childrenChildren === undefined) {
        return [
            ...prevArgs,
            windupFromString(" ", {
                element: children.type,
                props: restProps,
                ...keyProp,
                ...paceMetaData,
                ...onCharMetaData
            })
        ];
    }
    if (typeof childrenChildren === "string") {
        return [
            ...prevArgs,
            windupFromString(childrenChildren, {
                element: children.type,
                props: restProps,
                ...keyProp,
                ...paceMetaData,
                ...onCharMetaData
            })
        ];
    }
    if (childrenChildren instanceof Function) {
        return [
            ...prevArgs,
            windupFromString(" ", {
                element: children.type,
                props: { children: childrenChildren, ...restProps },
                ...keyProp,
                ...paceMetaData,
                ...onCharMetaData
            })
        ];
    }
    const newArgs = React.Children.toArray(childrenChildren).reduce(reduceWindupArgs, []);
    const argsWithMetadata = newArgs.map(member => {
        if (memberIsWindup(member)) {
            const [played, remaining, metadata] = member;
            return [
                played,
                remaining,
                {
                    ...paceMetaData,
                    ...onCharMetaData,
                    ...metadata
                }
            ];
        }
        return member;
    });
    return [
        ...prevArgs,
        newWindup(argsWithMetadata, {
            element: children.type,
            props: restProps,
            ...keyProp,
            ...paceMetaData,
            ...onCharMetaData
        })
    ];
}
function buildKeyString(children) {
    if (children === null || children === undefined) {
        return "";
    }
    return React.Children.map(children, child => {
        if (typeof child === "string") {
            return child;
        }
        if (typeof child === "number") {
            return child.toString();
        }
        if (React.isValidElement(child)) {
            return `#${child.key || ""}<${buildKeyString(child.props.children)}>`;
        }
        return "";
    }).join(",");
}
function useChildrenMemo(factory, children) {
    const memoChildren = useMemo(factory, [buildKeyString(children)]);
    return memoChildren;
}
const WindupChildren = ({ children, onFinished, skipped }) => {
    const windupInit = useChildrenMemo(() => {
        return newWindup(React.Children.toArray(children).reduce(reduceWindupArgs, []), { element: undefined });
    }, children);
    const { windup, skip, rewind, isFinished } = useWindup(windupInit, {
        onFinished,
        skipped
    });
    return (React.createElement(WindupContext.Provider, { value: {
            skip,
            rewind,
            isFinished
        } }, renderChildrenWindup(windup)));
};
export default WindupChildren;
